import React, { useRef, useLayoutEffect } from "react"
import { useForm, ValidationError } from "@formspree/react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

//Styles
import { form, textarea, success } from "../form/Form.module.css"
import { button } from "../elements/Button.module.css"

// Init ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

export default function Form() {
  //Set GSAP Ref
  let el = useRef()

  // Init useLayoutEffect Hook
  useLayoutEffect(() => {
    //Set GSAP Selector Utility
    const q = gsap.utils.selector(el)

    // Tie GSAP wrapper to Ref
    const wrapper = el.current

    // Animate child elements From
    gsap.fromTo(
      q("div"),
      {
        y: 30,
        opacity: 0,
      },
      // Animate child elements To + scrollTrigger out
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: wrapper,
          start: "top+=200px bottom+=200px",
          scrub: 0.1,
        },
      }
    )

    // Kill GSAP Animation scrollTrigger
    return () => ScrollTrigger.getAll().forEach(t => t.kill())
  }, [])

  // useState for form handler
  const [state, handleSubmit] = useForm("xrgjakpp")

  return (
    <>
      <form
        ref={el}
        className={form}
        onSubmit={handleSubmit}
        method="post"
        action="https://formspree.io/f/xrgjakpp"
      >
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <div>
          <input id="name" type="text" placeholder="Name" name="Name" />
        </div>

        <div>
          <input
            id="email"
            type="email"
            name="Email"
            placeholder="Your Email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>

        <div>
          <input
            id="phone"
            type="text"
            placeholder="Phone"
            name="Phone Number"
            required
          />
        </div>

        <div>
          <select name="Regarding" required defaultValue={"none"}>
            <option value="none" disabled>
              Enquiring about...
            </option>
            <option value="General">General</option>
            <option value="Moorings">Moorings</option>
            <option value="Repairs & Maintenance">
              Repairs &amp; Maintenance
            </option>
          </select>
        </div>

        <div className={textarea}>
          <textarea
            id="message"
            name="Message"
            placeholder="Message"
            rows="8"
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>

        <button className={button} type="submit" disabled={state.submitting}>
          Submit
        </button>

        {state.succeeded && (
          <div className={success}>
            <p>
              Your email has sent successfully. We'll get back to you as soon as
              we can!
            </p>
          </div>
        )}
      </form>
    </>
  )
}
