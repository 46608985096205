import * as React from "react"

//Data Hooks
import { useDetailsQuery } from "../hooks/useDetailsQuery"

//Components
import Layout from "../components/layout/Layout"
import FullWidthBlock from "../components/content/FullWidthBlock"
import Form from "../components/form/Form"

export default function Contact() {
  //WP Data
  const details = useDetailsQuery()

  return (
    <Layout>
      <div className="wrapper sub_page">
        <FullWidthBlock
          title="Have a question? Get in touch!"
          fancyBgText="Here to Help"
        />
      </div>

      <FullWidthBlock
        content="We're a busy bunch, so dropping us a line is the quickest way to get in touch."
        bigText={details.wp.companyDetails.companyDetails.phoneNumber}
        background="brand"
        fancyBgText="Call us"
      />

      <div className="wrapper reduced_h">
        <FullWidthBlock
          headStyle="special"
          title="Or drop us an email!"
          content="It can take a little longer for us to get back to you by email but if you're not in a rush then this is the best way to contact us."
        />
        <Form />
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <>
      <title>Contact | Lower Park Marina</title>
      <meta name="description" content="Get in touch with us." />
    </>
  )
}
